<template>
  <v-app data-app class="orders">
    <div style="display: flex; overflow-x: scroll; padding: 5px;background-color: #ebedef" ref="batteries">
      <v-card height="auto" width="auto" style="margin-right: 10px !important;" class="mr-4 stats_day"
              v-for="(stat, date, index) in orderStats"
              :style="[6,7].includes($moment(date).isoWeekday()) ? 'background: #dbf9db' : ''"
              :key="index"
              @click="setCardFilter(date);"
              :class="{stats_day_selected: filters.dateType==1 && filters.datetime_from==filters.datetime_to && filters.datetime_to==date, today: today===$moment(date).format('YYYY-MM-DD')}">
        <v-card-text style="min-width: 115px; padding: 3px 10px;">
          <v-subheader style="margin-bottom: 5px">{{ $moment(date).locale('ru').format('dd').toUpperCase() }},
            {{ $moment(date).format('DD.MM') }}
          </v-subheader>
          <v-progress-linear :value="stat.progress" height="4" :color="stat.color"
                             style="margin-bottom: 5px">
            <div style="background: #000; width: 1px; height: 4px; position: absolute"
                 :style="'left:'+ (stat.marks[0]/stat.marks[2])*100 + '%'"></div>
            <div style="background: #000; width: 1px; height: 4px; position: absolute"
                 :style="'left:'+ (stat.marks[1]/stat.marks[2])*100 + '%'"></div>
          </v-progress-linear>
          <v-subheader><b>{{ parseInt(stat.total / 1000) || 0 }}к</b> {{ $store.state.user.city.country.currency }}, {{
              stat.count
            }} шт
          </v-subheader>
          <v-subheader>
            <v-tooltip bottom nudge-right="120px">
              <template v-slot:activator="{ on, attrs }">
                <b v-bind="attrs" v-on="on" style="margin-right: 5px">
                  {{ stat.workers_count }}/{{ Number(stat.workersNew_count) }}
                </b> кл/ст
              </template>
              <span>
                  <ol v-if="stat.cleaners && stat.cleaners.notNew">
                    <li v-for="cleaner in stat.cleaners.notNew.concat(stat.cleaners.isNew)">
                      {{ Number(cleaner.isNew) ? 'Стажер ' : '' }}{{
                        cleaner.name
                      }} {{ Number(cleaner.ordersCount) ? '(' + cleaner.ordersCount + ' зак)' : '' }}
                    </li>
                  </ol>
                </span>
            </v-tooltip>
          </v-subheader>
          <template v-if="$moment(date).format('YYYY-MM-DD')<today">
            <v-progress-linear :value="stat.progress_oform" height="4" :color="stat.oform_color"
                               style="margin-bottom: 5px">
              <div style="background: #000; width: 1px; height: 4px; position: absolute"
                   :style="'left:'+ (stat.marks[0]/stat.marks[2])*100 + '%'"></div>
              <div style="background: #000; width: 1px; height: 4px; position: absolute"
                   :style="'left:'+ (stat.marks[1]/stat.marks[2])*100 + '%'"></div>
            </v-progress-linear>
            <v-subheader><b>{{ parseInt(stat.oform_total / 1000) || 0 }}к</b> {{
                $store.state.user.city.country.currency
              }},
              {{
                stat.oform_count
              }} шт
            </v-subheader>
          </template>
          <template v-else>
            <v-subheader style="font-size: 10px">
              <template v-for="(value, name) in stat.koefs">
                <v-tooltip top v-if="value"
                           :key="name">
                  <template v-slot:activator="{ on, attrs }">
                  <span style="flex-grow: 1;flex-basis: 0;text-align: center"
                        :style="value ? `color: ${parseFloat(value)>1 ? 'green' : 'red'}` : ''"
                        v-bind="attrs"
                        v-on="on">
                    {{
                      (parseFloat(value) > 1 ? '+' : '-') + Math.round(Math.abs(parseFloat(value) * 100 - 100)) + '%'
                    }}
                  </span>
                  </template>
                  <span>{{ $store.state.cleanTypes[name] }} повыш коэф</span>
                </v-tooltip>
                <span v-else style="flex-grow: 1;flex-basis: 0;text-align: center">---</span>
              </template>
            </v-subheader>
            <v-subheader style="font-size: 10px">
              <template v-for="(value, name) in stat.koefs2">
                <v-tooltip top v-if="value"
                           :key="name">
                  <template v-slot:activator="{ on, attrs }">
                  <span style="flex-grow: 1;flex-basis: 0;text-align: center"
                        :style="value ? `color: ${parseFloat(value)>1 ? 'green' : 'red'}` : ''"
                        v-bind="attrs"
                        v-on="on">
                    {{
                      (parseFloat(value) > 1 ? '+' : '-') + Math.round(Math.abs(parseFloat(value) * 100 - 100)) + '%'
                    }}
                  </span>
                  </template>
                  <span>{{ $store.state.cleanTypes[name] }} повыш коэф</span>
                </v-tooltip>
                <span v-else style="flex-grow: 1;flex-basis: 0;text-align: center">---</span>
              </template>
            </v-subheader>
            <v-subheader
                style="display: inline-flex;justify-content: space-around;width: 100%;font-weight: bold;font-size: 12px">
              <span v-for="type in ['standart','general', 'remont']">{{ stat.types_count[type] }}</span>
            </v-subheader>
          </template>
        </v-card-text>
      </v-card>
    </div>
    <div style="display: flex;padding: 5px;background-color: #ebedef">
      <v-card>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="py-0">
              <h4 class="mb-0">Статистика</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <LineChartGenerator
                  :chart-options="chartOptions"
                  :chart-data="barChartData"
                  chart-id="bar-chart"
                  :width="400"
                  :height="300"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </div>
    <v-container>
      <v-card>
        <v-card-text class="order-filters">
          <router-link to="/create">
            <v-btn style="margin-right: 5px" color="primary" :small="$vuetify.breakpoint.width < 480">
              <v-icon dense>mdi-plus</v-icon>
              Новый
            </v-btn>
          </router-link>
          <v-btn
              :small="$vuetify.breakpoint.width < 480"
              @click="changeValue('lead'); loading_lead = !loading_lead;getOrders()"
              :loading="loading_lead"
              :class="filters.lead ? 'v-btn-info' : 'v-btn-transp'">Входящие
            <CBadge v-if="$store.state.updatingInfo.incoming > 0" color="danger">{{
                $store.state.updatingInfo.incoming
              }}
            </CBadge>
          </v-btn>
          <v-btn
              @click="changeValue('confirm'); loading_confirm = !loading_confirm;getOrders();"
              :loading="loading_confirm"
              :class="filters.confirm ? 'v-btn-success' : 'v-btn-transp'"
              :small="$vuetify.breakpoint.width < 480">На подтверждение
            <CBadge v-if="$store.state.updatingInfo.confirm > 0" color="success">{{
                $store.state.updatingInfo.confirm
              }}
            </CBadge>
          </v-btn>
          <v-btn
              @click="changeValue('waiting'); loading_waiting = !loading_waiting;getOrders();"
              :class="filters.waiting ? 'v-btn-info' : 'v-btn-transp'"
              :loading="loading_waiting"
              :small="$vuetify.breakpoint.width < 480">Ожидают
            <CBadge v-if="$store.state.updatingInfo.waiting > 0" color="warning">{{
                $store.state.updatingInfo.waiting
              }}
            </CBadge>
          </v-btn>
          <v-btn
              @click="changeValue('trash'); loading_trash = !loading_trash;filters.dateType=0;getOrders()"
              :class="filters.trash ? 'v-btn-danger' : 'v-btn-transp'"
              :loading="loading_trash"
              :small="$vuetify.breakpoint.width < 480"
          >
            <v-icon dense class="mr-1" :dark="!!filters.trash">mdi-delete</v-icon>
            Корзина
          </v-btn>
          <v-btn
              @click="filters = {...initialFilters};getOrders()"
              class="v-btn-transp"
              :small="$vuetify.breakpoint.width < 480"
          >
            <v-icon dense class="mr-1">mdi-cancel</v-icon>
            Сбросить
          </v-btn>
          <v-btn
              @click="sendNotification()"
              :loading="loading_sendNotification"
              color="primary"
              :small="$vuetify.breakpoint.width < 480"
          >
            <v-icon class="mr-1">mdi-email-send-outline</v-icon>
            Отправить ({{ selectedAndDisplayedOrders.length || orders.length }} шт)
          </v-btn>
          <v-btn target="_blank" style="background: orange!important;"
                 @click="$refs.lenta.open(filters.datetime_from, filters.datetime_to);">
            Лента
          </v-btn>
          <v-btn
              v-if="$store.state.user.login && ['nk', 'root', 'zina1'].includes($store.state.user.login.toLowerCase())"
              :href="$store.state.server.url+'order/xlsx?token='+$store.state.server.token" icon>
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn color="blue"
                 :href="'#/day_orders?date='+filters.datetime_from" target="_blank" icon>
            <v-icon>mdi-calendar-month-outline</v-icon>
          </v-btn>
          <v-btn color="blue" @click="$refs.ControlPhotos.open(orders.map(o=>o.n_id))" icon>
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="6" class="mobile-col-basis">
                <multiselect
                    :options="$store.state.orderStatuses.filter(item=>item.value!=4)"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Выберите статусы заказа"
                    label="label"
                    track-by="value"
                    :searchable="false"
                    v-model="filters.statusValue"
                    selectedLabel="Выбрано"
                    style="min-height: 30px"
                    selectLabel=""
                    deselectLabel=""
                    @input="getOrders()"
                    @change="getOrders()"
                >
                </multiselect>
              </v-col>
              <v-col cols="1" class="mobile-col-basis">
                <v-row>
                  <v-col cols="8">
                    <v-subheader>Картой</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                        v-model="filters.cardPayment"
                        dense
                        true-value="1"
                        false-value=""
                        name="cardPayment"
                        label=""
                        @change="getOrders()"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3" class="mobile-col-basis">
                <v-autocomplete
                    :loading="loading_phones"
                    v-model="filters.client_id"
                    placeholder="Поиск заказов по телефону клиента"
                    :items="phones"
                    hide-no-data
                    :search-input.sync="searchPhones"
                    outlined
                    :no-filter="true"
                    item-value="n_id"
                    item-text="phone_name"
                    @change="getOrders()"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field placeholder="Промокод" outlined v-model="filters.promo"
                              @change="getOrders"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.width < 480 ? 6 : 1">
                <v-row>
                  <v-btn @click="filters.dateType = (filters.dateType+1)%2;applyDateInterval()">
                    {{ filters.trash ? dateTypeOptionsForTrash[filters.dateType] : dateTypeOptions[filters.dateType] }}
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="9" class="mobile-col-basis">
                <v-btn v-for="(item, index) in dateIntervals" v-if="!item.values"
                       :key="index"
                       style="margin:5px"
                       :style="filters.datetime_from===item.from && filters.datetime_to===item.from ? '' : 'background: #eee !important;'"
                       small
                       @click="applyDateInterval(item.from)">
                  {{ item.label }}
                </v-btn>
                <v-menu
                    v-else
                    open-on-hover
                    bottom
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        style="background: #eee !important;margin:5px"
                        @click="filters.periodType=0;applyDateInterval(item.from, item.to)"
                    >
                      {{ item.label }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                        dense
                        v-for="(subItem, index) in item.values"
                        :key="index"
                        @click="filters.periodType=0;applyDateInterval(subItem.from, subItem.to)"
                    >
                      <v-list-item-title>{{ subItem.label }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn @click="filters.periodType = (filters.periodType+1)%2;applyDateInterval()">{{
                    ['Период', 'День'][filters.periodType]
                  }}
                </v-btn>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.width < 480 ? 6 : 1">
                <v-row>
                  <v-menu
                      ref="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      left
                      bottom
                      nudge-bottom="35"
                      fixeds
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          outlined
                          :value="$root.dateToRus(filters.datetime_from)"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      />
                    </template>
                    <v-date-picker
                        v-model="filters.datetime_from"
                        no-title
                        scrollable
                        first-day-of-week="1"
                        locale="ru"
                        :reactive="true"
                        @change="getOrders()"
                    />
                  </v-menu>
                </v-row>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.width < 480 ? 6 : 1">
                <v-row>
                  <v-menu
                      ref="menu"
                      v-if="!filters.periodType"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      left
                      bottom
                      nudge-bottom="35"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          outlined
                          :value="$root.dateToRus(filters.datetime_to)"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      />
                    </template>
                    <v-date-picker
                        v-model="filters.datetime_to"
                        no-title
                        scrollable
                        first-day-of-week="1"
                        locale="ru"
                        @change="getOrders()"
                    />
                  </v-menu>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col>
              <p v-if="ordersInfo" style="line-height: 35px;margin-bottom: 0;">
                <v-chip
                    :color="(filters.dateType==1 && filters.datetime_from==filters.datetime_to && orderStats[filters.datetime_from])? {red: '#f44336', orange: '#ff9800', green:'rgb(219, 249, 219)'}[orderStats[filters.datetime_from].color]: 'rgb(202, 243, 255)'"
                    class="mr-2" label>
                  <b style="margin-right: 5px">{{ ordersInfo.count || '0' }}</b> заказов на <b style="margin-left: 5px">{{
                    $root.printCost(ordersInfo.sum)
                  }}</b>
                </v-chip>
                <template
                    v-for="(item) in [{i: 2, icon: 'cash-100', title: 'Наличными'}, {i: 1, icon: 'credit-card-outline', title: 'По карте'}, {i: 3, icon: 'bank', title: 'По счёту'}]">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-chip :color="+filters.paymentType === item.i ? '#9BF0F5' : '#fff'" class="mr-2" label
                                @click="onChangeFilterPaymentType(item.i)">
                      <v-icon size="20" style="margin-right: 5px" color="green">mdi-{{ item.icon }}</v-icon>
                {{
                            $root.printCost(ordersInfo.byPaymentType && ordersInfo.byPaymentType[item.i] ? ordersInfo.byPaymentType[item.i].sum : 0)
                          }}
                        </v-chip>
                        </span>
                    </template>
                    <span>{{ item.title }}</span>
                  </v-tooltip>
                </template>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-chip color="#fff" class="mr-2" label>
                <v-icon size="20" style="margin-right: 5px" color="green">mdi-taxi</v-icon>
                        {{ $root.printCost(ordersInfo.taxi) }}
                        </v-chip>
                      </span>
                  </template>
                  <span>Такси</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-chip color="#fff" class="mr-2" label>
                <v-icon size="20" style="margin-right: 5px" color="green">mdi-wallet-giftcard</v-icon>
                        {{ $root.printCost(ordersInfo.bonus) }}
                        </v-chip>
                      </span>
                  </template>
                  <span>Оплачено бонусами</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-chip color="#fff" class="mr-2" label>
                <v-icon size="20" style="margin-right: 5px" color="green">mdi-sale</v-icon>
                        {{ $root.printCost(ordersInfo.discountTotal || 0) }}
                        </v-chip>
                      </span>
                  </template>
                  <span>Скидки</span>
                </v-tooltip>
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card>
        <v-data-table
            hover
            :items="orders"
            :headers="filters.trash ? tableFields : ComputedtableFields"
            class="stripped"
            :loading="loading"
            :footer-props="{'items-per-page-options':[50]}"
            no-data-text="Заказов нет"
            loading-text="Загрузка..."
            mobile-breakpoint="0"
        >
          <template v-if="!filters.trash" v-slot:header.comment="props"></template>
          <template v-slot:item="{item, index}">
            <router-link :to="`/orders/${item.n_id}`" custom v-slot="{ navigate }">
              <tr style="cursor: pointer" @click="navigate" @keypress.enter="navigate" role="link">
                <td v-on:click.stop="">
                  <div style="display: flex">
                    <v-checkbox v-on:click.stop="setSelected(item.n_id)"
                                :input-value="selectedOrders.includes(item.n_id)"></v-checkbox>
                    <v-tooltip v-if="parseInt(item.createdIn)===9" right>
                      <template v-slot:activator="{ on, attrs }">
                        <span style="width: 14px;
    height: 14px;
    border-radius: 16px;
    cursor: pointer;
    background: orange;
    margin-right: 5px;
    margin-top: 2px;
    vertical-align: middle;" v-bind="attrs"
                              v-on="on"></span>
                      </template>
                      <span>Заказ создан автоматически<br> по подписке</span>
                    </v-tooltip>
                    <v-tooltip top v-for="(option, index2) in options"
                               v-if="item[option.option] > 0 || item.type == option.option" :key="index2">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="padding: 0 3px;font-size: 18px;margin-top: -2px;"
                             v-if="option.emoji">{{ option.emoji }}
                        </div>
                        <img v-else :src="option.src"
                             v-bind="attrs"
                             v-on="on"
                             width="20"
                             height="20">
                      </template>
                      <span>{{ option.tip }}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="utmAdv[item.get.utm_source]">
                      <template v-slot:activator="{ on, attrs }">
                        <img v-bind="attrs" v-on="on" style="border-radius: 3px;"
                             :style="item.get.utm_medium==='cpc'? 'border: 2px solid #9BF0F5':''" class="mr-1"
                             :src="`/img/adv/${item.get.utm_source}.svg`" height="20">
                      </template>
                      <span>{{
                          `${$root.capitalize(item.get.utm_source)} ${item.get.utm_medium === 'cpc' ? ' (Переход по рекламе)' : '(Переход не по рекламе - SEO, карты и тд)'}`
                        }}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="advIcons[Number(item.client.adv)]">
                      <template v-slot:activator="{ on, attrs }">
                        <img v-bind="attrs" v-on="on" style="filter: grayscale(1);margin-top: 2px"
                             :src="advIcons[item.client.adv]" height="16">
                      </template>
                      <span>{{ $store.state.findAdv(item.client.adv) }} (Указал менеджер)</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.tasks.length">
                      <template v-slot:activator="{ on, attrs }">
                        <router-link :to="`/orders/${item.n_id}`" custom v-slot="{ navigate }">
                          <v-icon
                              @click="navigate" @keypress.enter="navigate" role="link"
                              size="20"
                              v-bind="attrs"
                              color="blue"
                              v-on="on">mdi-calendar-check
                          </v-icon>
                        </router-link>
                      </template>
                      Задачи по заказу:
                      <ol>
                        <li v-for="task in item.tasks">{{ task.title }}</li>
                      </ol>
                    </v-tooltip>
                  </div>
                </td>
                <td v-on:click.stop=""
                    :style="parseInt(item.client.important) ? 'color: red': ''">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                    <span v-if="item.clientStats.likes > 0" style="color: green"
                          v-bind="attrs"
                          v-on="on">{{ item.clientStats.likes }}</span>
                    </template>
                    <span>Положительные отзывы</span>
                  </v-tooltip>

                  {{ item.clientStats.likes > 0 && item.clientStats.dislikes > 0 ? '/' : '' }}

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                    <span v-if="item.clientStats.dislikes > 0" style="color: red;margin-right: 5px;"
                          v-bind="attrs"
                          v-on="on">{{ item.clientStats.dislikes }}</span>
                    </template>
                    <span>Отрицательные отзывы</span>
                  </v-tooltip>
                  <span style="color: #df9000;font-weight: bold;margin-bottom: -5px;display: block;"
                        v-if="!(+item.client.depositStandart)">Новый клиент</span>
                  <ClientLink style="display: block" :value="item.client"></ClientLink>
                </td>
                <td v-if="filters.trash">{{ item.comment_cancel }}<br v-if="item.comment_cancel"/>{{ item.comment }}
                </td>
                <td :style="['background: #ffe7fb', 'background: #caf3ff', ''][item.colorType]">
                  {{ $root.dateToRus(item.datetimecleanObj.date) }} {{ item.datetimecleanObj.time }}
                </td>
                <td>
                  {{ $root.dateToRus(item.datetimeObj.date) }} {{ item.datetimeObj.time }}
                </td>
                <td style="white-space: normal;">
                  <OrderAddress :value="item.address"></OrderAddress>
                </td>
                <td>
                  <OrderStatus :value="item"></OrderStatus>
                </td>
                <td>
                  <span style="white-space: nowrap;" class="cleaner" v-for="cleaner in item.cleaners"
                        :key="cleaner.n_id">
                    <v-tooltip top
                               v-if="cleaner.status === '1' && showCleanerConfirmStatus.includes(parseInt(item.orderStatus))">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            size="16"
                            v-bind="attrs"
                            v-on="on">mdi-email-outline
                        </v-icon>
                      </template>
                      <span>Ожидает подтверждения от клинера</span>
                    </v-tooltip>
                    <v-tooltip top
                               v-if="cleaner.status === '2' && showCleanerConfirmStatus.includes(parseInt(item.orderStatus))"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            size="16"
                            v-bind="attrs"
                            v-on="on">mdi-check
                        </v-icon>
                      </template>
                      <span>Клинер подтвердил</span>
                    </v-tooltip>
                    <CleanerLink style="display: inline-block;" class="mr-2" :value="cleaner"></CleanerLink>
                  </span>
                </td>
                <td>
                  <div>{{ item.manager.fname }}</div>
                </td>
                <td>
                  <div>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon size="20" v-if="parseInt(item.paymentType)===1"
                                v-bind="attrs"
                                v-on="on"
                                :style="item.paymentType === '1' && item.client.defaultCard ? 'color: green' : 'color: red'">
                          mdi-credit-card-outline
                        </v-icon>
                      </template>
                      <span>Оплата картой</span><br>
                      <span>{{
                          item.paymentType === '1' && item.client.defaultCard ? 'Карта привязана' : 'Карта не привязана'
                        }}</span>
                    </v-tooltip>
                    {{ item.total }}{{ $store.state.user.city.country.currency }}
                  </div>
                </td>
                <td>
                  <div>{{ item.promo }}
                    {{
                      parseInt(item.discountPromo) ? item.discountPromo + $store.state.user.city.country.currency : parseInt(item.discount) ? item.discount + '%' : ''
                    }}
                  </div>
                </td>
                <td>
                  <div>{{ item.taxi }}{{ $store.state.user.city.country.currency }}</div>
                </td>
              </tr>
            </router-link>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <Lenta ref="lenta"></Lenta>
    <ControlPhotos ref="ControlPhotos"/>
  </v-app>
</template>

<script>
import {Server} from "@/api/server";
import Multiselect from 'vue-multiselect'
import Lenta from "./modals/Lenta";
import InfoTip from "../../components/InfoTip";
import CleanerLink from "../../components/CleanerLink";
import ClientLink from "../../components/ClientLink";
import OrderAddress from "../../components/OrderAddress";
import OrderStatus from "../../components/OrderStatus";

import {Line as LineChartGenerator} from "vue-chartjs/legacy"
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement, CategoryScale, LinearScale, PointElement
} from 'chart.js'
import FacebookAuth from "@/views/Dashboard/FacebookAuth";
import YandexAuth from "@/views/Dashboard/YandexAuth";
import GoogleAuth from "@/views/Dashboard/GoogleAuth";
import DatePeriod from "@/components/DatePeriod";
import ControlPhotos from "./modals/ControlPhotos";

ChartJS.register(ChartJSPluginDatalabels, Title, Tooltip, Legend, ArcElement, CategoryScale, LineElement, LinearScale, PointElement)

const server = new Server();

export default {
  components: {
    ControlPhotos,
    OrderStatus,
    OrderAddress,
    ClientLink,
    CleanerLink,
    InfoTip,
    Multiselect, Lenta,
    FacebookAuth, YandexAuth, GoogleAuth, DatePeriod,
    LineChartGenerator
  },
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    let tomorrow = this.$moment().add(1, 'days').format('YYYY-MM-DD');
    let dayAfterTomorrow = this.$moment().add(2, 'days').format('YYYY-MM-DD');
    let yesterday = this.$moment().add(-1, 'days').format('YYYY-MM-DD');
    let dayBeforeYesterday = this.$moment().add(-2, 'days').format('YYYY-MM-DD');
    let currentWeekStart = this.$moment().startOf('isoWeek').format('YYYY-MM-DD');
    let currentWeekEnd = this.$moment().endOf('isoWeek').format('YYYY-MM-DD');

    let prevWeekStart = this.$moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
    let prevWeekEnd = this.$moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
    let nextWeekStart = this.$moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
    let nextWeekEnd = this.$moment().add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');


    let currentMonthStart = this.$moment().startOf('month').format('YYYY-MM-DD');
    let currentMonthEnd = this.$moment().endOf('month').format('YYYY-MM-DD');
    let prevMonthStart = this.$moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    let prevMonthEnd = this.$moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    let nextMonthStart = this.$moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
    let nextMonthEnd = this.$moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
    console.log(this.$route.query);
    return {
      today,
      tomorrow,
      dayAfterTomorrow,
      yesterday,
      dayBeforeYesterday,
      currentWeekStart,
      currentWeekEnd,
      prevWeekStart,
      prevWeekEnd,
      nextWeekStart,
      nextWeekEnd,
      currentMonthStart,
      currentMonthEnd,
      prevMonthStart,
      prevMonthEnd,
      nextMonthStart,
      nextMonthEnd,
      utmAdv: {
        facebook: 1,
        yandex: 2,
        google: 3,
        instagram: 7,
      },
      advIcons: [
        '',
        '/img/adv/facebook.svg',
        '/img/adv/yandex.png',
        '/img/adv/google.png',
        '/img/adv/undefined.png',
        '',
        '/img/adv/vk.png',
        '/img/adv/instagram.png',
        '/img/adv/other.png',
        '/img/adv/undefined.png',
        '/img/adv/avito.png',
        '/img/adv/2gis.png',
        '',
        '',
        '',
        '/img/adv/marquiz.png',
        '',
        '/img/adv/yandexmaps.png',
      ],
      filters: {
        lead: 0,
        trash: +this.$route.query.trash || 0,
        waiting: 0,
        confirm: 0,
        datetime_from: this.$route.query.datetime_from || today,
        datetime_to: this.$route.query.datetime_to || today,
        statusValue: [],
        dateType: +this.$route.query.dateType || 1,
        periodType: 0,
        cardPayment: 0,
      },
      initialFilters: {
        lead: 0,
        trash: 0,
        waiting: 0,
        confirm: 0,
        datetime_from: today,
        datetime_to: today,
        statusValue: [],
        dateType: 1,
        periodType: 0,
        cardPayment: 0,
      },
      backgroundColor: 0,
      loading: true,
      loading_lead: false,
      loading_trash: false,
      loading_waiting: false,
      loading_confirm: false,
      loading_apply: false,
      loading_sendNotification: false,
      loading_phones: false,
      menu_datetimeTo: false,
      menu_datetimecleanFrom: false,
      menu_datetimecleanTo: false,
      showCleanerConfirmStatus: this.$store.state.user.city.code === 'msk' ? [0] : [0, 1, 6, 7, 8],
      tableFields: [
        {value: '', text: ''},
        {value: 'client', text: 'Клиент'},
        {value: 'comment', text: 'Комментарий'},
        {value: 'datetimecleanObj', text: 'Дата уборки'},
        {value: 'datetimeObj', text: 'Дата оформления'},
        {value: 'address', text: 'Адрес'},
        {value: 'orderStatus', text: 'Статус'},
        {value: 'workers', text: 'Исполнители'},
        {value: 'manager', text: 'Оформил'},
        {value: 'total', text: 'Сумма'},
        {value: 'promo', text: 'Промо'},
        {value: 'taxi', text: 'Такси'},
      ],
      orders: [],
      selectedOrders: [],
      dateTypeOptions: ['Оформ', 'Испол'],
      dateTypeOptionsForTrash: ['Дата удал.', 'Испол'],

      dateIntervals: [
        {label: 'Позавчера', from: dayBeforeYesterday},
        {label: 'Вчера', from: yesterday},
        {label: 'Сегодня', from: today},
        {label: 'Завтра', from: tomorrow},
        {label: 'Послезавтра', from: dayAfterTomorrow},
        {
          label: 'Эта неделя', from: currentWeekStart, to: currentWeekEnd, values: [
            {label: 'Пред неделя', from: prevWeekStart, to: prevWeekEnd},
            {label: 'След неделя', from: nextWeekStart, to: nextWeekEnd},
          ]
        },
        {
          label: 'Этот месяц', from: currentMonthStart, to: currentMonthEnd, values: [
            {label: 'Пред месяц', from: prevMonthStart, to: prevMonthEnd},
            {label: 'След месяц', from: nextMonthStart, to: nextMonthEnd},
          ]
        },
      ],
      ordersInfo: {byPaymentType: {}},

      options: [
        {option: 'standart', src: '/img/standart.png', tip: 'Быстрая'},
        {option: 'general', src: '/img/general.png', tip: 'Генеральная'},
        {option: 'remont', src: '/img/remont.png', tip: 'После ремонта'},
        {option: 'other', src: '/img/other.png', tip: 'Другое'},

        {option: 'pilesos', src: '/img/pilesos.png', tip: 'Пылесос'},
        {option: 'delivery', src: '/img/ic_o.png', tip: 'Доставка'},
        {option: 'himchistka', src: '/img/himchistka.png', tip: 'Химчистка'},
        {option: 'getkey', src: '/img/key.png', tip: 'Заехать за ключами'},
        {option: 'sendkey', src: '/img/key2.png', tip: 'Отвезти ключи'},
        {option: 'parogen', src: '/img/parogen.png', tip: 'Парогенератор'},
        {option: 'windowNum', src: '/img/window.png', tip: 'Мытье окон'},
        {option: 'eco', src: '/img/eco.png', tip: 'Эко-уборка'},
        {option: 'animals', emoji: '🙀', tip: 'Есть животные'},
      ],
      orderStats: {},
      searchPhones: null,
      client_id: null,
      phones: [],
      requestNum: 0,
      firstLoad: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: c => false,
            font: {
              weight: 'bold'
            },
            formatter: Math.round
          }
        },
      }
    }
  },
  name: 'Tables',
  methods: {
    setSelected(order_id) {
      let index = this.selectedOrders.indexOf(order_id);
      if (index >= 0) {
        this.selectedOrders.splice(index, 1);
      } else {
        this.selectedOrders.push(order_id);
      }
      this.selectedOrders = this.selectedOrders.slice(0);
      console.log(this.selectedOrders);
    },
    getOrders(reloadTable = true) {
      let params = this.applyFilters(reloadTable);
      if (reloadTable) {
        this.orders = [];
        this.loading = true;
      } else {
        if (params.diff > 3 && this.firstLoad) {
          setTimeout(() => {
            if (this.$router.currentRoute.path === '/orders') this.getOrders(false)
          }, 5000);
          return;
        }
      }
      this.requestNum++;
      let requestNum = this.requestNum;
      server.request('order/get', params, (data) => {
        if (requestNum !== this.requestNum) return;
        this.orders = data.response;
        this.orderStats = data.stats;
        let date = '';
        let colorType = 0;
        this.orders.forEach(el => {
          if (el.datetimecleanObj.date !== date) {
            colorType++;
            date = el.datetimecleanObj.date;
          }
          el.colorType = colorType % 2;
          el.options = this.options;
        });
        this.ordersInfo = data.orders_info;
        this.loading = false;
        this.loading_apply = false;
        this.loading_lead = false;
        this.loading_waiting = false;
        this.loading_confirm = false;
        this.loading_trash = false;
        this.firstLoad = true;
        this.$forceUpdate();
        if (!reloadTable) setTimeout(() => {
          if (this.$router.currentRoute.path === '/orders') this.getOrders(false)
        }, 5000)
      }, () => {
        if (!reloadTable) setTimeout(() => {
          if (this.$router.currentRoute.path === '/orders') this.getOrders(false)
        }, 5000)
      })
    },
    editOrder(id) {
      this.$router.push(`/orders/${id}`);
    },
    setCardFilter(date) {
      this.filters.dateType = 1;
      this.filters.datetime_from = date;
      this.filters.datetime_to = date;
      this.filters.lead = 0
      this.filters.waiting = 0
      this.filters.confirm = 0
      this.getOrders();
    },
    applyDateInterval(from = null, to = null) {
      if (from) {
        this.filters.datetime_from = from;
        this.filters.datetime_to = to ? to : from;
      }
      this.filters.lead = 0
      this.filters.waiting = 0
      this.filters.confirm = 0
      this.getOrders()
    },
    applyFilters(saveFilters = true) {
      let filteredParams = {...this.filters};
      if (filteredParams.periodType === 1) filteredParams.datetime_to = filteredParams.datetime_from;
      filteredParams.diff = new Date(filteredParams.datetime_to) - new Date(filteredParams.datetime_from);
      filteredParams.diff /= 1000 * 3600 * 24;
      if (filteredParams.dateType === 1) {
        filteredParams.datetimeclean_from = filteredParams.datetime_from;
        filteredParams.datetimeclean_to = filteredParams.datetime_to;
        delete (filteredParams.datetime_from);
        delete (filteredParams.datetime_to);
      }
      if (Number(filteredParams.cardPayment) == 1) filteredParams.paymentType = 1;
      if (filteredParams.trash) {
        filteredParams.statuses = [4];
      } else {
        filteredParams.statuses = filteredParams.statusValue ? filteredParams.statusValue.map(el => el.value) : '';
      }
      if (saveFilters) this.$root.saveData('orders', 'filters', this.filters);
      return filteredParams;
    },
    changeValue(value) {
      let boolParams = ['lead', 'trash', 'waiting', 'confirm'];
      this.filters[value] = this.filters[value] ? 0 : 1;
      if (!this.filters[value]) return;
      for (let i in boolParams) {
        if (boolParams[i] == value) continue;
        this.filters[boolParams[i]] = (boolParams[i] === value ? 1 : 0);
      }
    },
    sendNotification() {
      let ids = [];
      if (this.selectedAndDisplayedOrders.length) ids = Object.values({...this.selectedAndDisplayedOrders});
      else this.orders.forEach(order => ids.push(order.n_id));
      if (!ids.length) return this.$root.notify('Не выбран ни один заказ', 'error');
      if (!confirm(`Отправить клинерам ${ids.length} заказов на подтверждение?`)) return;
      this.loading_sendNotification = true;
      this.$store.state.server.request('order/sendNotifications', {ids}, () => {
        this.loading_sendNotification = false;
        this.$root.notify('Уведомления отправлены', 'success')
        this.selectedOrders = []
      }, (data) => {
        if (data && data.error) this.$root.notify(data.error, 'error')
        this.loading_sendNotification = false;
      })
    },
    findByPhone(tel) {
      this.loading_phones = true
      this.$store.state.server.request('client/find', {phone: tel}, (data) => {
        this.phones = data.response;
        this.phones.forEach(el => {
          el.phone_name = el.phone + ' ' + el.name;
        })
        this.loading_phones = false
      })
    },
    loadFilters() {
      let saved = this.$root.getData('orders', 'filters') || {};
      for (let field in this.filters) {
        if (saved.hasOwnProperty(field)) this.filters[field] = saved[field];
      }
    },
    statusColor(status) {
      let colors = ["red", "green", "violine", "#3000ff", "black", "gray", "#ff0404", "#7420ff", "#e50083"];
      return colors[Number(status)] || 'black';
    },
    setBatteryToday() {
      let todayCard = this.$refs.batteries.getElementsByClassName('today')[0];
      if (!todayCard) return setTimeout(() => this.setBatteryToday(), 100);
      this.$refs.batteries.scrollLeft = todayCard.offsetLeft - 10
    },
    onChangeFilterPaymentType(paymentType) {
      this.filters.paymentType = +this.filters.paymentType === paymentType ? '' : paymentType;
      this.getOrders();
    }
  },
  mounted() {
    if (!Object.keys(this.$route.query).length) this.loadFilters();
    this.getOrders(false);
    this.setBatteryToday();
  },
  computed: {
    ComputedtableFields() {
      return this.tableFields.filter(el => el.value !== 'comment')
    },
    selectedAndDisplayedOrders() {
      let ids = [];
      this.orders.forEach(order => ids.push(order.n_id));
      // return [];
      if (typeof this.selectedOrders.filter !== "function") {
        return [];
      }
      return this.selectedOrders.filter(id => ids.includes(id));
    },
    barChartData() {
      let barData = []
      for (let date in this.orderStats) {
        barData.push(this.orderStats[date].total || 0)
      }
      let barData2 = []
      for (let date in this.orderStats) {
        barData2.push(this.orderStats[date].oform_total || 0)
      }
      let barData3 = []
      for (let date in this.orderStats) {
        barData3.push(this.orderStats[date].marks[1])
      }
      let barData4 = []
      for (let date in this.orderStats) {
        barData4.push(this.orderStats[date].deleted_crm_total || 0)
      }
      let barData5 = []
      for (let date in this.orderStats) {
        barData5.push(this.orderStats[date].deleted_client_total || 0)
      }
      let barData6 = []
      for (let date in this.orderStats) {
        let val = this.orderStats[date];
        barData6.push((val.oform_total || 0) - (val.deleted_crm_total || 0) - (val.deleted_client_total || 0))
      }
      return {
        labels: Object.keys(this.orderStats).map(date => {
          return `${this.$moment(date).format('ddd, DD.MM')}`
        }),
        datasets: [
          {
            label: 'Исполненные',
            backgroundColor: '#58e558',
            borderColor: '#58e558',
            data: barData,
            datalabels: {
              align: 'end',
              anchor: 'start'
            },
            tension: 0.2,
          },
          {
            label: 'Оформленные',
            backgroundColor: '#3399ff',
            borderColor: '#3399ff',
            data: barData2,
            datalabels: {
              align: 'end',
              anchor: 'start'
            },
            tension: 0.2,
          },
          {
            label: 'План',
            backgroundColor: '#656565',
            borderColor: '#656565',
            data: barData3,
            pointRadius: 1,
            datalabels: {
              align: 'end',
              anchor: 'start'
            },
            tension: 0,
          },
          {
            label: 'Удалено в црм',
            backgroundColor: '#ff4545',
            borderColor: '#ff4545',
            data: barData4,
            datalabels: {
              align: 'end',
              anchor: 'start'
            },
            tension: 0.2,
          },
          {
            label: 'Удалено клиентом',
            backgroundColor: '#910000',
            borderColor: '#910000',
            data: barData5,
            datalabels: {
              align: 'end',
              anchor: 'start'
            },
            tension: 0.2,
          },
          {
            label: 'Оформ минус удалено',
            backgroundColor: '#ffc833',
            borderColor: '#ffc833',
            data: barData6,
            datalabels: {
              align: 'end',
              anchor: 'start'
            },
            tension: 0.2,
          },
        ]
      }
    }

  },
  watch: {
    searchPhones(val) {
      val && this.findByPhone(val)
    },
  }
}
</script>
