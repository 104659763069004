<template>
  <v-dialog data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Лента изменений
        <InfoTip position="bottom" value="Здесь отображаются изменения стоимости и удаления заказов"></InfoTip>
      </v-card-title>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            item-key="n_id"
            :footer-props="{'items-per-page-options':[-1]}"
            no-data-text="Истории нет"
            loading-text="Загрузка..."
        >
          <template v-slot:item="{item}">
            <tr>
              <td>{{ $root.dateToRus(item.datetimeObj.date) }} {{ item.datetimeObj.time }}</td>
              <td>
                <OrderLink :value="item.order"></OrderLink>
              </td>
              <td>
                <template v-if="item.user_type === 'manager'">
                  Менеджер {{ item.user.fname }} ({{ item.user.login }})
                </template>
                <template v-else-if="item.user_type === 'worker'">
                  Клинер {{ item.user.name }}
                </template>
                <template v-else-if="item.user_type === 'client'">
                  Клиент {{ item.user.name }}
                </template>
              </td>
              <td style="max-width: 300px;white-space: normal;">{{ item.type }}</td>
              <td>
                <span v-if="item.params['Статус'] && item.params['Статус'][1]==='Отменен'" style="color: red">
                  Статус: Отменен<br>
                  {{ item.order.comment }}
                </span>
                <span v-if="item.params['В кассу']">
                  В кассу: {{ $root.printCost(item.params['В кассу'][0]) }} -> {{ $root.printCost(item.params['В кассу'][1]) }}
                    <v-chip text-color="white" v-if="parseInt(item.params['В кассу'][0])" small :color="item.params['В кассу'][1] - item.params['В кассу'][0]>0?'green':'red'">
                      {{ $root.printCost(item.params['В кассу'][1] - item.params['В кассу'][0], 1) }}
                    </v-chip>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import OrderLink from "../../../components/OrderLink";
import InfoTip from "../../../components/InfoTip";

export default {
  name: "Lenta",
  components: {InfoTip, OrderLink},
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    return {
      loading: false,
      opened: false,
      filters: {
        date: [today, today]
      },
      headers: [
        {text: 'Дата', value: 'datetimeObj'},
        {text: 'Заказ', value: 'order.datetimeclean'},
        {text: 'Кто изменил', value: 'user'},
        {text: 'Тип', value: 'type'},
        {text: 'Изменения', value: 'params'},
      ],
      items: [],
    }
  },
  methods: {
    open(from, to) {
      this.items = [];
      this.opened = true;
      this.loading = true;
      if (from && to) this.filters.date = [from, to];
      this.getHistory()
    },
    getHistory() {
      this.$store.state.server.request(`order/lenta`, this.filters, (data) => {
        this.items = data.response;
        this.loading = false;
      }, () => {
        this.loading = false;
      })
    }
  }
}
</script>