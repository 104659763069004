<template>
  <a v-on:click.stop="$eventBus.$emit('openClient', value.n_id)">
    <v-tooltip top v-if="parseInt(value.important)">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs"
                v-on="on" color="red">mdi-exclamation-thick
        </v-icon>
      </template>
      <span>Важный клиент</span>
    </v-tooltip>
    <v-chip v-if="parseInt(value.black_list)" color="black" style="color: white" x-small>Черный список</v-chip>
    <span style="text-transform: capitalize">{{ value.name || 'Без имени' }}</span>
    <div style="white-space: nowrap">{{ value.phone }}</div>
  </a>
</template>
<script>

export default {
  name: "ClientLink",
  props: {
    value: {},
  },
}
</script>
<style scoped>
a {
  color: black !important;
  cursor: pointer;
  background: #c7dedd;
  border-radius: 5px;
  padding: 2px 5px;
  display: inline-block;
  margin: 5px 0;
}

a:hover {
  background: rgba(199, 222, 221, 0.6);
  color: black;
}
</style>
