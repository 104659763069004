<template>
  <v-dialog data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Настойка Google аналитики</v-card-title>
      <v-card-text>
        <v-btn @click="loadLink">Авторизация</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SavingParam from "../../components/SavingParam";

export default {
  name: "GoogleAuth",
  components: {SavingParam},
  data() {
    return {
      opened: false
    }
  },
  methods: {
    open() {
      this.opened = true;
    },
    loadLink() {
      // this.$store.state.server.request('dashboard/authLink', {instrument: 'google'}, (data) => {
      window.open(this.$store.state.server.url + 'auth/authLink?instrument=google');
      // });
    }
  }
}
</script>