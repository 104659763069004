<template>
  <v-dialog scrollable data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="24">mdi-close</v-icon>
      <v-card-title>Контрольные фото</v-card-title>
      <v-card-subtitle>Фото с данных заказов, которые клинеры прикрепили в качестве контрольных</v-card-subtitle>
      <v-card-text>
        <v-data-table
            hover
            :items="response.photos"
            :headers="[{text:'Заказ/клинер', value: 'orderID'}].concat(response.titles.map(t=>({text:t})))"
            item-key="id"
            :loading="loading"
            :footer-props="{'items-per-page-options':[-1]}"
            no-data-text="Заказов нет"
            loading-text="Загрузка..."
            mobile-breakpoint="0">
          <template v-slot:item="{item, index}">
            <tr>
              <td>
                <OrderLink blank :value="response.orders[item.orderID]"/>
                <CleanerLink :value="response.cleaners[item.cleanerID]"/>
              </td>
              <td v-for="(title, i) in response.titles">
                <img style="padding: 5px 0" width="150" :src="item.photos[i]"/>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import OrderLink from "../../../components/OrderLink";
import CleanerLink from "../../../components/CleanerLink";

export default {
  name: "ControlPhotos",
  components: {CleanerLink, OrderLink},
  data() {
    return {
      orderIDs: [],
      opened: false,
      loading: false,
      response: {photos: [], titles: []},
    }
  },
  methods: {
    open(orderIDs) {
      if (!orderIDs.length) return this.$root.notify('Таблица с заказами пустая', 'error')
      this.opened = true;
      this.loading = true;
      this.orderIDs = orderIDs;
      this.$store.state.server.request('order/controlPhotos', {ids: this.orderIDs}, data => {
        this.response = data.response;
        this.loading = false;
      });
    },
  }
}
</script>
