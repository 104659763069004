<template>
  <v-dialog data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Настойка Facebook аналитики</v-card-title>
      <v-card-text>
        <v-btn v-if="$store.state.user.city.id==1" @click="loadLink">Авторизация</v-btn>
        <p>Для подключения Facebook Маркетинга необходимо создать приложение в аккаунте Facebook и ввести данные ниже.</p>
        <a href="https://developers.facebook.com/async/registration/" target="_blank">Создать приложение Facebook</a>
        <v-row class="row-d-block">
          <v-subheader>ID аккаунта Facebook Маркетинг</v-subheader>
          <SavingParam name="fb_account_id"></SavingParam>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>ID приложения</v-subheader>
          <SavingParam name="fb_app_id"></SavingParam>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Секрет приложения</v-subheader>
          <SavingParam name="fb_app_secret"></SavingParam>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Маркер</v-subheader>
          <SavingParam name="fb_app_token"></SavingParam>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Стоп-слова в названии кампаний через запятую. Кампании с такими словами в названии не будут учитываться. (Например, Вакансии, ДругойГород)</v-subheader>
          <SavingParam name="fb_stop_words" placeholder="Вакансии, другой_город"></SavingParam>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading" @click="check">Готово</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import SavingParam from "../../components/SavingParam";

export default {
  name: "FacebookAuth",
  components: {SavingParam},
  data() {
    return {
      opened: false,
      loading: false,
    }
  },
  methods: {
    open() {
      this.opened = true;
    },
    loadLink() {
      window.open(this.$store.state.server.url + 'auth/authLink?instrument=facebook', '_blank');
    },
    check() {
      this.loading = true;
      this.$store.state.server.request('dashboard/checkFb', {}, () => {
        // this.$root.notify('Подключение к Facebook работает.');
        location.reload();
      }, () => {
        this.loading = false;
        // this.$root.notify('Не получается подключиться к Facebook.', 'error');
      });
    }
  }
}
</script>