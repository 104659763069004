<template>
  <router-link :target="blank?'_blank':''" @click.stop :to="`/orders/${value.n_id}`" class="d-block order-link"
               :style="{background: value.orderStatusColor || '#d3ebff'}"
               style="width: fit-content;">
    <div class="">
      <template v-if="value.address && value.address.street">
        {{ value.address.street.substr(0, 20) + (value.address.street.length > 20 ? '...' : '') }},
        д{{ value.address.house }},
      </template>
      <template v-else>{{ value.orderStatusStr || 'Нет адреса' }}</template>
      <!--      <template v-else>{{ value.orderStatusStr }}</template>-->
      <!--      <br>-->
      <template v-if="value.datetimecleanObj && value.datetimecleanObj.date">
        {{ $root.dateToRus(value.datetimecleanObj.date) }} {{ value.datetimecleanObj.time }},
      </template>
      <template v-if="value.total">
        {{ $root.printCost(value.total) }}
      </template>
      <template v-if="+value.rate">, {{ value.rate }}
        <v-icon color="amber" size="12">mdi-star</v-icon>
      </template>
      <template v-if="fullInfo">
        <span>{{ value.address.flattype }} комн., </span>
        <span>{{ $store.state.cleanTypes[value.type].toLowerCase() }}</span>
      </template>
    </div>
    <div class="full-info" v-if="fullInfo">
      <div class="toggle-button" @click.prevent.stop="toggleFullInfo">
        <span v-if="!isFullInfoVisible">Подробнее<v-icon>mdi-chevron-down</v-icon></span>
        <span v-else>Свернуть<v-icon>mdi-chevron-up</v-icon></span>
      </div>
      <template v-if="isFullInfoVisible">
        <v-row><b>Доп.услуги:</b> <span v-if="!Object.keys(selectedServices).length">Не выбраны</span></v-row>
        <template v-if="$store.state.user && $store.state.user.services && Object.keys(selectedServices).length">
          <v-row v-for="(obj, key) in selectedServices" :key="key">
            {{ obj.label }}: {{ obj.value }}
          </v-row>
        </template>
      </template>
    </div>
  </router-link>
</template>
<script>

export default {
  name: "OrderLink",
  props: {
    value: {type: Object},
    blank: {type: Boolean},
    fullInfo: {type: Boolean, default: false}
  },
  data() {
    return {
      isFullInfoVisible: false
    }
  },
  computed: {
    selectedServices() {
      let services = {};
      if (this.$store.state.user.services) {
        for (let serviceKey in this.$store.state.user.services.services) {
          if (+this.value[serviceKey]) {
            services[serviceKey] = {
              value: this.value[serviceKey],
              label: this.$store.state.user.services.services[serviceKey].label
            };
          }
        }
        for (let serviceKey in this.$store.state.user.services.check_services) {
          if (+this.value[serviceKey]) {
            services[serviceKey] = {
              value: this.value[serviceKey],
              label: this.$store.state.user.services.check_services[serviceKey].label
            };
          }
        }
        let order_dry_services = JSON.parse(this.value.services);
        if (order_dry_services && order_dry_services.length) {
          for (let serviceKey in this.$store.state.user.services.dry) {
            let dry_service = order_dry_services.find(d_s => d_s.name === serviceKey);
            if (dry_service) {
              services[serviceKey] = {
                value: dry_service.count,
                label: this.$store.state.user.services.dry[serviceKey].label,
                him_jivotnie: dry_service.him_jivotnie,
                him_smell: dry_service.him_smell,
                him_koja: dry_service.him_koja,
              };
            }
          }
        }
      }
      return services;
    }
  },
  methods: {
    toggleFullInfo() {
      this.isFullInfoVisible = !this.isFullInfoVisible;
    }
  }
}
</script>

<style scoped lang="scss">
.order-link {
  width: fit-content;
  padding: 4px 8px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 12px;
  line-height: 150%;
  color: #333333;
}

.toggle-button {
  font-size: 14px;
  cursor: pointer;
}
</style>
